import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { isEqual } from 'lodash';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'main',
    component: () => import('../views/Main.vue'),
  },
  {
    path: '/book',
    name: 'book',
    component: () => import('../views/Main.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  if (isEqual(to.name, 'login')) next({ name: 'main' });
  else next();
});

export default router;
