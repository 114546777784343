import { IState } from '@/models/state/state.model';
import { InjectionKey } from 'vue';
import { createStore, Store } from 'vuex';
import { appsModule } from './modules/apps.module';

export const key: InjectionKey<Store<IState>> = Symbol();

export const store = createStore<IState>({
  state: {
    isMobile: false,
    isPad: false,
    isCalendarAnchor: true,
  },

  actions: {
    changeIsMobile: ({ commit }, payload: boolean): void => {
      commit('setIsMobile', payload);
    },
    changeIsPad: ({ commit }, payload: boolean): void => {
      commit('setIsPad', payload);
    },
    changeCalendarAnchor: ({ commit }, payload: boolean): void => {
      commit('setCalendarAnchor', payload);
    }
  },

  mutations: {
    setIsMobile: (store, payload: boolean): void => {
      store.isMobile = payload;
    },
    setIsPad: (store, payload: boolean): void => {
      store.isPad = payload;
    },
    setCalendarAnchor: (store, payload: boolean): void => {
      store.isCalendarAnchor = payload;
    },
  },

  getters: {
    getIsMobile: store => store.isMobile,
    getIsPad: store => store.isPad,
    getIsCalendarAnchor: store => store.isCalendarAnchor,
  },
  
  modules: { 
    appsModule,
  },
});
