import { IAppsState } from "@/models/state/apps/apps-state.model";
import { Module } from "vuex";

export const appsModule: Module<IAppsState, unknown> = {
    state: {
        apps: [
            {
                id: 1,
                name: 'Manhattan Exchange',
                title: 'Non-custodial crypto wallets & exchange platform',
                screenLinks: [
                    '/media/portfolio/manhattan_exchange/app_incubator_me_1.png',
                    '/media/portfolio/manhattan_exchange/app_incubator_me_2.png',
                    '/media/portfolio/manhattan_exchange/app_incubator_me_3.png',
                    '/media/portfolio/manhattan_exchange/app_incubator_me_4.png',
                    '/media/portfolio/manhattan_exchange/app_incubator_me_5.png',

                ],
                logoLink: '/media/apps_logos/app_me.png',
                description: {
                    problems: [
                            'Different blockchain networks required attention and custom approach.',
                    ],
                    role: 'Design, develop and release crypto exchange platform',
                    stack: [
                        'SwiftUI', 'Blockchain', 'Node.js', 'Vue.js',
                    ],
                    platforms: [
                        'iOS', 'Admin panel',
                    ],
                    timeSpend: '2973 hrs (~12 months)',
                },
                appLinks: [
                    { 'apple': 'https://apps.apple.com/us/app/manhattan-exchange/id1616776900' },
                    { 'google': '' },
                    { 'website': '' },

                ],
            },

            {
                id: 2,
                name: 'Luxy Play',
                title: 'Private club booking app',
                screenLinks: [
                    '/media/portfolio/luxy_play/app_incubator_luxy_1.png',
                    '/media/portfolio/luxy_play/app_incubator_luxy_2.png',
                    '/media/portfolio/luxy_play/app_incubator_luxy_3.png',
                    '/media/portfolio/luxy_play/app_incubator_luxy_4.png',
                    '/media/portfolio/luxy_play/app_incubator_luxy_5.png',
                ],
                logoLink: '/media/apps_logos/app_luxy.png', 
                description: {
                    problems: [
                            'No APIs to work with - had to create semi-automatic updates via emails and admin panel for the client',
                    ],
                    role: 'Design and develop the app from the idea',
                    stack: [
                        'SwiftUI', 'Vue.js', 'Node.js',
                    ],
                    platforms: [
                        'iOS app', 'Backend', 'Admin panel',
                    ],
                    timeSpend: '630 hours (~ 4 month)',
                },
                appLinks: [
                    { 'apple': '' },
                    { 'google': '' },
                    { 'website': 'https://www.luxyplay.com' }
                ],
            },

            {
                id: 3,
                name: 'Gemtone',
                title: 'Music social media app',
                screenLinks: [
                    '/media/portfolio/gemtone/app_incubator_gemtone_1.png',
                    '/media/portfolio/gemtone/app_incubator_gemtone_2.png',
                    '/media/portfolio/gemtone/app_incubator_gemtone_3.png',
                ],
                logoLink: '/media/apps_logos/app_gt.png',
                description: {
                    problems: [
                            'Spotify does not provide API for giving recently played tracks to other users - had to realize JWT authentication via their web SDK',
                    ],
                    role: 'Develop the app from the design and release to App Store',
                    stack: [
                        'SwiftUI', 'Spotify', 'Node.js',
                    ],
                    platforms: [
                        'iOS app', 'Backend',
                    ],
                    timeSpend: '458 hours (~ 10 weeks)',
                },
                appLinks: [
                    { 'apple': 'https://apps.apple.com/nl/app/gemtone/id1582750194' },
                    { 'google': '' },
                    { 'website': 'http://gemtone.eu' }
                ],
            },

            {
                id: 4,
                name: 'Mercedes Exclusive',
                title: 'Concierge app',
                screenLinks: [
                    '/media/portfolio/mersedes/app_incubator_mersedes_1.png',
                    '/media/portfolio/mersedes/app_incubator_mersedes_2.png',
                    '/media/portfolio/mersedes/app_incubator_mersedes_3.png',
                    '/media/portfolio/mersedes/app_incubator_mersedes_4.png',
                ],
                logoLink: '/media/apps_logos/app_mersedes.png',
                description: {
                    problems: [
                            'Custom tab bar has unusual form buttons - had to implement it via bezier lines',
                    ],
                    role: 'Port from ionic (iOS) and integrate with Firebase (backend)',
                    stack: [
                        'UIKit', 'Firebase', 'Realm', 'APNs',
                    ],
                    platforms: [
                        'iOS app',
                    ],
                    timeSpend: '200 hours (~ 6 weeks)',
                },
                appLinks: [
                    { 'apple': '' },
                    { 'google': '' },
                    { 'website': 'https://www.sixbyseven.de' }
                ],
            },

            {
                id: 5,
                name: 'Mana',
                title: 'Kegel workouts for men',
                screenLinks: [
                    '/media/portfolio/mana/app_incubator_mana_1.png',
                    '/media/portfolio/mana/app_incubator_mana_2.png',
                    '/media/portfolio/mana/app_incubator_mana_3.png',
                    '/media/portfolio/mana/app_incubator_mana_4.png',
                    '/media/portfolio/mana/app_incubator_mana_5.png',
                ],
                logoLink: '/media/apps_logos/app_mana.png',
                description: {
                    problems: [
                            'Apple WatchConnectivity Framework has a bug - had to implement manual syncing.',
                            'Had to implied custom graphs.',
                    ],
                    role: 'Develop the app from Figma design',
                    stack: [
                        'SwiftUI', 'Realm', 'Lottie',
                    ],
                    platforms: [
                        'iOS app + widget', 'WatchOS',
                    ],
                    timeSpend: '707 hours (~ 7.5 months)',
                },
                appLinks: [
                    { 'apple': '' },
                    { 'google': '' },
                    { 'website': '' }
                ],
            },

            {
                id: 6,
                name: 'Match3 Battle',
                title: 'Turn-based multiplayer game',
                screenLinks: [
                    '/media/portfolio/match3_battle/app_incubator_match3_battle_1.png',

                ],
                logoLink: '/media/apps_logos/app_match3_b.png',
                description: {
                    problems: [
                            'GameCenter had some glitches and not much of documentation - had to figured out many things on our own',
                    ],
                    role: 'Create the game from design and specifications',
                    stack: [
                        'Swift', 'SpriteKit', 'GameCenter',
                    ],
                    platforms: [
                        'iOS app',
                    ],
                    timeSpend: '423.5 hours (~ 10 weeks)',
                },
                appLinks: [
                    { 'apple': '' },
                    { 'google': '' },
                    { 'website': '' }
                ],
            },

            {
                id: 7,
                name: 'Aavia',
                title: 'Hormone wellness app',
                screenLinks: [
                    '/media/portfolio/aavia/app_incubator_aavia_1.png',
                    '/media/portfolio/aavia/app_incubator_aavia_2.png',
                    '/media/portfolio/aavia/app_incubator_aavia_3.png',
                    '/media/portfolio/aavia/app_incubator_aavia_4.png',
                    '/media/portfolio/aavia/app_incubator_aavia_5.png',
                ],
                logoLink: '/media/apps_logos/app_aavia.png',
                description: {
                    problems: [
                            'Had to quickly dive deep into someone else code and fix it.',
                    ],
                    role: 'Re-do upselling screens and some navigation logic',
                    stack: [
                        'Swift',
                    ],
                    platforms: [
                        'iOS app',
                    ],
                    timeSpend: '76 hours (~ 2 weeks)',
                },
                appLinks: [
                    { 'apple': 'https://apps.apple.com/us/app/aavia-ultimate-self-care-app/id1378160961' },
                    { 'google': '' },
                    { 'website': 'https://aavia.io' }
                ],
            },

            // {
            //     id: 8,
            //     name: 'Allergy',
            //     title: 'Find allergen by scanning labels',
            //     screenLinks: [
            //         '/media/portfolio/',
            //         '/media/portfolio/',
            //         '/media/portfolio/',

            //     ],
            //     logoLink: '/media/apps_logos/app_aavia.svg',
            //     description: {
            //         problems: [
            //                 'Connect UIKit based library to SwiftUI - had to use UIViewRepresentable with some adjustments.',
            //         ],
            //         role: 'Develop the app from the Sketch design and specifications',
            //         stack: [
            //             'Swift',
            //         ],
            //         platforms: [
            //             'iOS app',
            //         ],
            //         timeSpend: '231 hours (~ 6 weeks)',
            //     },
            //     appLinks: [
            //         { 'apple': '' },
            //         { 'google': '' },
            //         { 'website': '' }
            //     ],
            // },

            // {
            //     id: 9,
            //     name: 'Valence Vibrations',
            //     title: 'Experience emotions as vibrations',
            //     screenLinks: [
            //         '/media/portfolio/',
            //         '/media/portfolio/',
            //         '/media/portfolio/',

            //     ],
            //     logoLink: '/media/apps_logos/app_aavia.svg',
            //     description: {
            //         problems: [
            //                 'Needed to integrate pre-proccessing step into the model - ended up retraining and optimizing the model.',
            //         ],
            //         role: '• Retrain audio classification ML model to make it work on iOS • Optimize model for better performance • Help with releasing to App Store',
            //         stack: [
            //             'UIKit', 'CreateML', 'Python',
            //         ],
            //         platforms: [
            //             'iOS app', 'WatchOS',
            //         ],
            //         timeSpend: '40 hours (~ 2 weeks)',
            //     },
            //     appLinks: [
            //         { 'apple': '' },
            //         { 'google': '' },
            //         { 'website': 'https://www.valencevibrations.com' }
            //     ],
            // },

            // {
            //     id: 10,
            //     name: 'EasyWakeup',
            //     title: 'Sleep cycle alarm clock',
            //     screenLinks: [
            //         '/media/portfolio/',
            //         '/media/portfolio/',
            //         '/media/portfolio/',

            //     ],
            //     logoLink: '/media/apps_logos/app_aavia.svg',
            //     description: {
            //         problems: [
            //                 'Before background mode exists we had to make the app track movements, keep the screen off and do not drain battery much - had to use tricks to make it work.',
            //                 'Basically invented silent sound hack..'
            //         ],
            //         role: 'Design, develop, release, and market the first smart alarm clock on the mobile',
            //         stack: [
            //             'Objective C', 'C++',
            //         ],
            //         platforms: [
            //             'iOS app',
            //         ],
            //         timeSpend: '400 hours (~ 4.5 years)',
            //     },
            //     appLinks: [
            //         { 'apple': '' },
            //         { 'google': '' },
            //         { 'website': '' }
            //     ],
            // },

            // {
            //     id: 11,
            //     name: '3D Person scanner',
            //     title: 'Photogrammetry app',
            //     screenLinks: [
            //         '/media/portfolio/',
            //         '/media/portfolio/',
            //         '/media/portfolio/',

            //     ],
            //     logoLink: '/media/apps_logos/app_aavia.svg',
            //     description: {
            //         problems: [
            //                 'Could not pass some parameters to Apple"\'"s Photogrammetry API - turned out it has a bug and nothing can be done from our end, had to semi-manually tweak final meshes.',
            //         ],
            //         role: 'Develop the app, distribute to 30+ iphones, make images of an object from different angles, sends into a local server, and then mesh it into one.',
            //         stack: [
            //             'SwiftUI', 'Photogrammetry', 'Object Capture',
            //         ],
            //         platforms: [
            //             'iOS', 'iPadOS', 'MacOS',
            //         ],
            //         timeSpend: '235 hours (~ 6 weeks)',
            //     },
            //     appLinks: [
            //         { 'apple': '' },
            //         { 'google': '' },
            //         { 'website': '' }
            //     ],
            // },

            // {
            //     id: 12,
            //     name: 'Warehouse app',
            //     title: 'An enterprise app',
            //     screenLinks: [
            //         '/media/portfolio/',
            //         '/media/portfolio/',
            //         '/media/portfolio/',

            //     ],
            //     logoLink: '/media/apps_logos/app_aavia.svg',
            //     description: {
            //         problems: [
            //                 'An outdated, repeated spaghetti code that needed to be fixed and improved - had to refactored networking layer to use Apple native frameworks',
            //         ],
            //         role: 'Design and develop 2-way sync backend<>app feature for a case when a user who goes offline/online',
            //         stack: [
            //             'SwiftUI', 'Photogrammetry', 'Object Capture',
            //         ],
            //         platforms: [
            //             'IOS',
            //         ],
            //         timeSpend: '254 hours (~ 1.7 months)',
            //     },
            //     appLinks: [
            //         { 'apple': '' },
            //         { 'google': '' },
            //         { 'website': '' }
            //     ],
            // },

            // {
            //     id: 13,
            //     name: 'Precision Match Timer',
            //     title: 'for PRS and NRL Matches',
            //     screenLinks: [
            //         '/media/portfolio/',
            //         '/media/portfolio/',
            //         '/media/portfolio/',

            //     ],
            //     logoLink: '/media/apps_logos/app_aavia.svg',
            //     description: {
            //         problems: [
            //                 'Needed to make the screen on all the time during the app running (2 mins long) - had to use Workout session',
            //         ],
            //         role: 'Develop the app from Figma design and release to App Store',
            //         stack: [
            //             'SwiftUI', 'UIKit',
            //         ],
            //         platforms: [
            //             'WatchOS',
            //         ],
            //         timeSpend: '254 hours (~ 1.7 months)',
            //     },
            //     appLinks: [
            //         { 'apple': 'https://apps.apple.com/app/id1519610860' },
            //         { 'google': '' },
            //         { 'website': 'https://www.joeloehle.com/precision-match-timer' }
            //     ],
            // },

        ]
    },

    actions: {},
    mutations: {},
    getters: {
        getApps: store => store.apps,
    }
}
